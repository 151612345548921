<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Supervisorio">
          <template slot="right">
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canList"
                  color="primary"
                  dark
                  class="ml-2 mb-2"
                  v-on="on"
                  >Indicadores</v-btn
                >
              </template>
              <indicadores-gerencimento-servico
                :headers="headers"
                @save="saveHeaders"
                @close="close"
                :localStorageKey="localStorageKey"
              />
            </v-dialog>
            <v-btn
              v-if="canList"
              color="primary"
              dark
              class="ml-2 mb-2"
              @click="handleGetGates"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <PesagemSupervisorio :activator="false" ref="pesagem" />
          <OcrManualSupervisorio :activator="false" ref="ocr_manual" />
          <v-dialog v-model="dialogPesagemHistory" max-width="1080px">
            <historico-pesagem
              @close="dialogPesagemHistory = false"
              :historico="historico"
            />
          </v-dialog>
          <listar-supervisorio
            :search="search"
            :headers="headersComputed"
            ref="listagem"
          />
        </BaseCard>
      </v-col>
    </v-row>
    <Message />
  </v-container>
</template>

<script>
import ListarSupervisorio from '@/components/patio/Supervisorio/ListarSupervisorio'
import PesagemSupervisorio from '@/components/patio/Supervisorio/pesagem/Componente'
import OcrManualSupervisorio from '@/components/patio/Supervisorio/ocrManual/Componente'
import IndicadoresGerencimentoServico from '@/components/patio/GerenciamentoServico/IndicadoresGerencimentoServico.vue'
import HistoricoPesagem from '@/components/patio/GerenciamentoServico/HistoricoPesagem'

import Message from '@/layouts/full-layout/message/Message.vue'

import { mapActions, mapState, mapMutations } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

import mqtt from 'mqtt'

export default {
  components: {
    ListarSupervisorio,
    IndicadoresGerencimentoServico,
    PesagemSupervisorio,
    OcrManualSupervisorio,
    Message,
    HistoricoPesagem,
  },
  provide() {
    return {
      pesar: this.pesar,
      ocrManual: this.ocrManual,
      openPreview: this.openPreview,
      repesar: this.repesar,
      reset: this.reset,
      confirmar: this.confirmar,
      comando_cancela_a: this.comando_cancela_a,
      comando_cancela_b: this.comando_cancela_b,
      ocrManualWebsocket: this.ocrManualWebsocket,
      openDialogPesagemHistory: this.openDialogPesagemHistory,
    }
  },
  data() {
    return {
      localStorageKey: 'headersSupervisorio',
      dialog: false,
      showComprovantes: false,
      dialogPesagemHistory: false,
      historico: [],
      search: '',
      loading: false,
      page_size: 1000,
      // items: [],
      timeout: undefined,
      socket: null,
      topic: null,
      page: {
        title: 'Supervisorio',
      },
      headers: [
        {
          habilited: false,
          block: false,
          text: 'Public Id',
          divider: true,
          value: 'public_id',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Descrição',
          divider: true,
          value: 'descricao',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Status Atual',
          divider: true,
          value: 'status_atual',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Mensagem',
          divider: true,
          value: 'mensagem',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Display',
          divider: true,
          value: 'display',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Sentido',
          divider: true,
          value: 'sentido',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Placa',
          divider: true,
          value: 'placa',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Cancela A',
          divider: true,
          value: 'lado_a',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Peso balança (Kg)',
          divider: true,
          value: 'peso_balanca',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Cancela B',
          divider: true,
          value: 'lado_b',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Status Camera',
          divider: true,
          value: 'cameras',
          class: 'primary white--text',
        },
        {
          text: 'Ações',
          divider: true,
          sortable: false,
          value: 'actions',
          class: 'primary white--text',
        },
      ],
      breadcrumbs: [
        {
          text: 'Pátio',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Supervisorio',
          disabled: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('agendamentos', ['agendamento']),

    headersComputed() {
      return this.headers.filter((header) => !!header.habilited)
    },

    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  watch: {
    headersComputed() {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.headers))
    },
  },
  async created() {
    this.setFiltrosGerenciamentoServicos()
    this.handleGetGates()
    // this.connectWebsocket()
    this.connectMqtt()
  },
  methods: {
    ...mapActions('template', ['errorMessage']),
    ...mapActions('patio', ['getAgendamentoGerenciamentoServico']),
    ...mapActions('supervisorio', ['getGates', 'atualizaGates']),
    ...mapMutations('supervisorio', ['ATUALIZA_GATE']),
    connectMqtt() {
      // Conectar ao broker MQTT via WebSocket
      this.topic = `/${this.empresaAtual.public_id}/supervisorio`
      this.socket = mqtt.connect('wss://api.rotacertasistemas.com.br:9001', {
        clean: true,
        // protocol: 'wss',
        keepalive: 60000, // Tempo de keepalive
        rejectUnauthorized: false, // Defina como 'true' em produção para maior segurança
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId: 'emqx_vue_' + Math.random().toString(16).substring(2, 8),
        username: 'rotacerta',
        password: '60wluzp6k06pMJEZ9dpC',
      })
      window.socket = this.socket
      // Quando o cliente se conectar ao broker MQTT
      this.socket.on('connect', () => {
        console.log('Conectado ao broker MQTT')
        // Assine o tópico desejado
        this.socket.subscribe(this.topic, { tos: 0 }, (err) => {
          if (!err) {
            console.log(`Inscrito no tópico: ${this.topic}`)
          } else {
            console.error('Erro ao se inscrever:', err)
          }
        })
      })
      // Quando uma mensagem for recebida
      this.socket.on('message', (topic, message) => {
        console.debug(
          `Mensagem recebida no tópico ${topic}:`,
          message.toString()
        )
        this.readMesage(message.toString())
      })
    },
    connectWebsocket() {
      this.socket = new WebSocket(
        // `ws://localhost:3000/?room=${this.empresaAtual.public_id}`
        `wss://ws.rotacerta.kmm.dev.br/?room=${this.empresaAtual.public_id}`
      )
      window.socket = this.socket
      this.socket.onmessage = (event) => {
        this.readMesage(event.data)
      }
    },
    readMesage(event) {
      try {
        const data = JSON.parse(event)
        if (data.public_id) {
          this.ATUALIZA_GATE(data)
        } else {
          console.error(data)
        }
      } catch (error) {
        console.log(event.data)
        console.log(error)
      }
    },
    async getGate(from_refresh = false) {
      try {
        // const { results } = await this.getGates({
        //   proprietario: this.empresaAtual.public_id,
        // })
        // this.items = results
        await this.atualizaGates({
          proprietario: this.empresaAtual.public_id,
          supervisorio: true,
        })
      } catch (error) {
        console.log(error)
        if (!from_refresh && error.refreshed) {
          return setTimeout(async () => {
            return await this.getGate(true)
          }, 3000)
        }
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async handleGetGates() {
      this.loading = true
      await this.getGate()
      this.loading = false
    },

    async getAgendamento(agendamento) {
      return await this.getAgendamentoGerenciamentoServico(agendamento)
    },
    async openDialogPesagemHistory({ item }) {
      this.dialogPesagemHistory = true
      const agendamento = await this.getAgendamento(item)
      this.historico = agendamento.pesagens
    },
    pesar({ item, tipo }) {
      // console.log(item, tipo)
      this.$refs['pesagem'].setup(
        {
          public_id: item.agendamento,
          placa: item.placa,
        },
        tipo,
        item.public_id
      )
    },
    publish(data, topic) {
      let jsonString = JSON.stringify(data)
      console.log(data)
      this.socket.publish(topic, jsonString, (err) => {
        if (err) {
          console.error('Erro ao publicar a mensagem:', err)
        } else {
          console.log('Mensagem publicada no tópico ' + topic)
        }
      })
    },
    openPreview({ item, camera }) {
      console.log(item, camera)
      let data = {
        gate_id: item.public_id,
        camera_id: camera.public_id,
        foto_ocr_manual: 1,
        request_timeout: 60,
      }
      let topic = `${this.topic}/foto_ocr_manual`
      this.publish(data, topic)
    },
    repesar({ item }) {
      let data = {
        gate_id: item.public_id,
        repesar: 1,
      }
      let topic = `${this.topic}/repesar`
      this.publish(data, topic)
    },
    reset({ item }) {
      let data = {
        gate_id: item.public_id,
        reset: 1,
      }
      let topic = `${this.topic}/reset`
      this.publish(data, topic)
    },
    confirmar({ item }) {
      let data = {
        gate_id: item.public_id,
        confirmar: 1,
      }
      let topic = `${this.topic}/confirmar`
      this.publish(data, topic)
    },
    ocrManual({ item }) {
      console.log(item)
      this.$refs['ocr_manual'].setup(item)
    },
    comando_cancela_a({ item, tipo }) {
      const tipos = ['lado_a', 'lado_b']
      let data = {
        gate_id: item.public_id,
        abertura_manual_cancela: 1,
        sentido: tipo,
        acao: !item[tipos[tipo]].cancela,
      }
      let topic = `${this.topic}/abre_cancela`
      this.publish(data, topic)
    },
    comando_cancela_b({ item, tipo }) {
      const tipos = ['lado_a', 'lado_b']
      let data = {
        gate_id: item.public_id,
        abertura_manual_cancela: 1,
        sentido: tipo,
        acao: !item[tipos[tipo]].cancela,
      }
      let topic = `${this.topic}/abre_cancela`
      this.publish(data, topic)
    },
    ocrManualWebsocket(data) {
      let topic = `${this.topic}/ocr_manual`
      this.publish(data, topic)
    },
    saveHeaders(headers) {
      if (!headers) return
      localStorage.setItem(this.localStorageKey, JSON.stringify(headers))
    },
    setFiltrosGerenciamentoServicos() {
      const headersSaved = localStorage.getItem(this.localStorageKey)
      const headersSavedParsed = JSON.parse(headersSaved)
      if (
        headersSaved &&
        Array.isArray(headersSavedParsed) &&
        headersSavedParsed.length > 0
      ) {
        const headersMap = this.headers.map((item) => item.value)
        // Pega apenas os itens que existem no headers (itens antigos são desconsiderados)
        const _headersSavedParsed = headersSavedParsed.filter((item) =>
          headersMap.includes(item.value)
        )
        const _headersSavedParsedMap = _headersSavedParsed.map(
          (item) => item.value
        )
        const headers_saved_map_habilited = _headersSavedParsed
          .filter((item) => item.habilited)
          .map((item) => item.value)
        const headers_saved_map_not_habilited = _headersSavedParsed
          .filter((item) => !item.habilited)
          .map((item) => item.value)
        // headersSavedParsed
        const newItems = this.headers
          .map((item, index) => {
            return [index, item]
          })
          .filter((item) => !_headersSavedParsedMap.includes(item[1].value))

        this.headers = _headersSavedParsed.map((item) => {
          let habilited = false
          if (headers_saved_map_habilited.includes(item.value)) {
            habilited = true
          } else {
            if (headers_saved_map_not_habilited.includes(item.value)) {
              habilited = false
            } else {
              // Para os novos
              habilited = item.habilited
            }
          }
          return {
            ...item,
            habilited,
          }
        })
        // Adiciona os novos itens na ordem que eles deveriam estar
        newItems.forEach((element) => {
          const [index, value] = element
          this.headers.splice(index, 0, value)
        })
        this.saveHeaders(this.headers)
      } else {
        this.saveHeaders(this.headers)
      }
    },
    close() {
      this.saveHeaders(this.headers)
      this.dialog = false
    },
    // async handleGetGates() {
    // const { listagem } = this.$refs
    // await listagem.handleGetGates()
    // },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.end()
    }
  },
}
</script>
