<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div v-if="activator">
      <v-btn color="error" dark class="mb-2" v-on="open">OCR Manual</v-btn>
    </div>
    <v-dialog v-model="dialog" v-if="dialog" max-width="700px">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-card>
          <v-card-title class="d-flex flex-column align-start">
            <span class="headline"
              >OCR Manual: {{ item ? item.descricao : '' }}</span
            >
            <span class="text-caption"
              >Gate: {{ item ? item.public_id : 'Gate não selecionado' }}</span
            >
            <span class="text-caption"
              >Câmera:
              {{
                editedItem.camera
                  ? editedItem.camera.public_id
                  : 'Câmera não selecionado'
              }}</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="placa"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="editedItem.camera"
                      label="Câmera"
                      hint="Escolha a camêra"
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-camera"
                      required
                      item-text="label"
                      item-value="public_id"
                      return-object
                      :items="cameras"
                      @change="openPreview({ item, camera: $event })"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="placa"
                    rules="required"
                  >
                    <v-text-field
                      v-model="editedItem.placa"
                      :disabled="!editedItem.camera"
                      label="Placa"
                      hint="Digite a placa"
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-alphabetical-variant"
                      v-mask="['XXX-XXXX']"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="10" offset-md="1">
                  <div style="max-width: 100%">
                    <img
                      :src="preview"
                      style="max-width: 100%"
                      v-if="preview"
                    />
                    <v-sheet color="darken-2" class="pa-3" v-else>
                      <v-skeleton-loader
                        class="mx-auto"
                        type="image"
                      ></v-skeleton-loader>
                    </v-sheet>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!canSave || invalid"
              @click="save"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { BRString2datetimeIso, parseDatetime } from '@/mixins/convertion'

export default {
  props: {
    activator: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  inject: {
    ocrManualWebsocket: {
      from: 'ocrManualWebsocket',
      default: {},
    },
    openPreview: {
      from: 'openPreview',
      default: {},
    },
  },
  mixins: [BRString2datetimeIso, parseDatetime],
  data() {
    return {
      loading: false,
      dialog: false,
      item: {
        descricao: '',
        public_id: null,
      },
      ocr_default: {
        placa: '',
        // public_id: null,
        gate_id: null,
        camera: null,
      },
      editedItem: {
        placa: '',
        // public_id: null,
        gate_id: null,
        camera: null,
      },
    }
  },
  methods: {
    ...mapActions('supervisorio', ['ocrManual']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    reset() {
      Object.assign(this.editedItem, this.ocr_default)
    },
    async setup(gate_service) {
      this.reset()
      this.item = gate_service
      this.editedItem.gate_id = gate_service.public_id
      this.$nextTick(() => {
        this.open()
      })
    },
    close() {
      this.dialog = false
    },
    open() {
      this.dialog = true
    },
    async save() {
      console.log(this.editedItem)
      console.log(this.item.cameras)
      try {
        const { camera, ...data } = this.editedItem
        if (camera) {
          data.camera_id = camera.public_id
          data.sentido = camera.sentido
        }
        await this.ocrManualWebsocket(data)
        this.successMessage('Ocr realizada!')
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      } finally {
        this.close()
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'empresaAtual']),
    canSave() {
      return this.editedItem.placa != ''
    },
    cameras() {
      return this.item.cameras
    },
    preview() {
      if (this.editedItem.camera) {
        if (this.editedItem.camera.public_id) {
          const camera = this.cameras.find(
            (c) => c.public_id == this.editedItem.camera.public_id
          )
          if (camera.base_64) {
            return `data:image/jpeg;base64,${camera.base_64}`
          }
        }
      }
      return false
    },
  },
  async created() {},
}
</script>
