<template>
  <div>
    <div v-if="canList">
      <listagem :headers="headers" :permission="permission" :data="items" />
    </div>
    <div v-else-if="!canList">
      <span class="red--text"
        >Você não tem permissão para visualizar as informações desta tela</span
      >
    </div>
  </div>
</template>

<script>
import Listagem from './listagem/Componente'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    headers: {
      type: Array,
    },
  },
  components: {
    Listagem,
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions('template', ['successMessage', 'errorMessage']),
    getTempo(item) {
      const diferencaEmMs = new Date() - Date.parse(item)
      const segundos = diferencaEmMs / 1000
      return segundos
    },
  },
  computed: {
    ...mapState('auth', ['user', 'empresaAtual']),
    ...mapState('supervisorio', ['gates']),
    items() {
      return this.gates
    },
    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },
  async created() {},
}
</script>
