<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div v-if="activator">
      <v-btn color="error" dark class="mb-2" v-on="open"
        >Pesar {{ tipo_str[tipo] }}</v-btn
      >
    </div>
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title class="d-flex flex-column align-start">
          <span class="headline"
            >Pesar {{ tipo_str[tipo] }}:
            {{
              editedItem.agendamento ? editedItem.agendamento.placa : ''
            }}</span
          >
          <span class="text-caption"
            >Agendamento:
            {{
              editedItem.agendamento && 'public_id' in editedItem.agendamento
                ? editedItem.agendamento.public_id
                : 'Agendamento não selecionado'
            }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="peso"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.peso"
                    label="Peso (Kg)"
                    :hint="`Peso de ${tipo_str[tipo]} em kilogramas`"
                    type="number"
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-weight"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="valor"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.data"
                    label="Data da pesagem"
                    :hint="`Data da pesagem de ${tipo_str[tipo]}`"
                    type="datetime"
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-mask="['##/##/#### ##:##:##']"
                    required
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <validation-provider v-slot="{ errors }" name="justificativa">
                  <v-select
                    dense
                    v-model="editedItem.justificativa"
                    label="Justificativa"
                    hint="Justificativa da pesagem"
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-swap-horizontal"
                    required
                    :readonly="readonly"
                    item-text="descricao"
                    item-value="id"
                    :items="justificativas"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" name="observacao">
                  <v-textarea
                    v-model="editedItem.observacao"
                    label="Observação"
                    :error-messages="errors"
                    persistent-hint
                    prepend-icon="mdi-invoice-text-edit"
                  >
                    <template v-slot:label>
                      <div>Observação <small>(Informações breves)</small></div>
                    </template>
                  </v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!canConfirm"
            @click="confirm"
            >Confirmar</v-btn
          >
          <v-btn color="blue darken-1" text :disabled="!canSave" @click="save"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { BRString2datetimeIso, parseDatetime } from '@/mixins/convertion'
import PatioApi from '@/services/patio/index'

export default {
  props: {
    activator: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mixins: [BRString2datetimeIso, parseDatetime],
  data() {
    return {
      loading: false,
      tipo_str: {
        1: 'Entrada',
        2: 'Saida',
      },
      dialog: false,
      tipo: 0,
      justificativas: [],
      pesagem_default: {
        public_id: '',
        peso: 0,
        manual: true,
        data: '',
        observacao: '',
        agendamento: { public_id: '', placa: '' },
        gate_service: '',
      },
      editedItem: { ...this.pesagem_default },
    }
  },
  methods: {
    ...mapActions('supervisorio', ['pesar']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    reset() {
      Object.assign(this.editedItem, this.pesagem_default)
    },
    async setup(agendamento, tipo, gate_service) {
      this.editedItem.agendamento = agendamento
      this.editedItem.gate_service = gate_service
      this.editedItem.data = this.parseDatetime(new Date())
      this.tipo = tipo
      this.justificativas = await PatioApi.getJustificativas(this.empresaAtual)
      this.open()
    },
    close() {
      this.dialog = false
    },
    open() {
      this.dialog = true
    },
    async confirm() {
      return await this.save(true)
    },
    async save(confirm = false) {
      let data
      try {
        data = this.BRString2datetimeIso(this.editedItem.data)
      } catch (error) {
        this.errorMessage('Data não foi informada corretamente!')
        return
      }
      const pesagem = {
        ...this.editedItem,
        tipo_pesagem: this.tipo,
        agendamento: this.editedItem.agendamento.public_id,
        data: data,
        confirm: confirm,
      }
      try {
        await this.pesar(pesagem)
        this.successMessage('Pesagem realizada!')
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'empresaAtual']),
    canSave() {
      return (
        this.editedItem.peso > 0 &&
        this.editedItem.agendamento.public_id &&
        this.editedItem.data
      )
    },
    canConfirm() {
      const confirm = true
      return this.canSave && confirm
    },
  },
  async created() {},
}
</script>
