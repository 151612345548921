var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.activator)?_c('div',[_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"error","dark":""}},_vm.open),[_vm._v("Pesar "+_vm._s(_vm.tipo_str[_vm.tipo]))])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-column align-start"},[_c('span',{staticClass:"headline"},[_vm._v("Pesar "+_vm._s(_vm.tipo_str[_vm.tipo])+": "+_vm._s(_vm.editedItem.agendamento ? _vm.editedItem.agendamento.placa : ''))]),_c('span',{staticClass:"text-caption"},[_vm._v("Agendamento: "+_vm._s(_vm.editedItem.agendamento && 'public_id' in _vm.editedItem.agendamento ? _vm.editedItem.agendamento.public_id : 'Agendamento não selecionado'))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"peso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Peso (Kg)","hint":("Peso de " + (_vm.tipo_str[_vm.tipo]) + " em kilogramas"),"type":"number","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-weight","required":""},model:{value:(_vm.editedItem.peso),callback:function ($$v) {_vm.$set(_vm.editedItem, "peso", $$v)},expression:"editedItem.peso"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##/##/#### ##:##:##']),expression:"['##/##/#### ##:##:##']"}],attrs:{"label":"Data da pesagem","hint":("Data da pesagem de " + (_vm.tipo_str[_vm.tipo])),"type":"datetime","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-calendar","required":""},model:{value:(_vm.editedItem.data),callback:function ($$v) {_vm.$set(_vm.editedItem, "data", $$v)},expression:"editedItem.data"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"justificativa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","label":"Justificativa","hint":"Justificativa da pesagem","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-swap-horizontal","required":"","readonly":_vm.readonly,"item-text":"descricao","item-value":"id","items":_vm.justificativas},model:{value:(_vm.editedItem.justificativa),callback:function ($$v) {_vm.$set(_vm.editedItem, "justificativa", $$v)},expression:"editedItem.justificativa"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"observacao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Observação","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-invoice-text-edit"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Observação "),_c('small',[_vm._v("(Informações breves)")])])]},proxy:true}],null,true),model:{value:(_vm.editedItem.observacao),callback:function ($$v) {_vm.$set(_vm.editedItem, "observacao", $$v)},expression:"editedItem.observacao"}})]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.canConfirm},on:{"click":_vm.confirm}},[_vm._v("Confirmar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.canSave},on:{"click":_vm.save}},[_vm._v("Salvar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }